<template>
    <div id="body">
        <div class="fatherBox">
            <div class="headerBox">
                <p>{{detailData.exam_name}} / {{ detailData.subject }}</p>
            </div>
            <div style="text-align:left;padding:20px 30px;color: #899;font-size:14px;">
                {{ detailData.exam_date }} | {{ detailData.exam_people }}人考试 | 满分{{ detailData.scoreDetail.total_score }}分 | 平均分{{ detailData.scoreDetail.avg_score }}分
            </div>
            <div class="stubody" style="margin:25px 40px 10px 10px;" v-if="detailData">
                <div style="text-align: left;width:93%">
                    <el-table :data="detailData.scoreDetail.list" v-loading="loading" style="margin-top: 31px;">
                        <el-table-column type="index" label="序号" width="auto" align="center">
                            <template slot-scope="scope">
                                {{ scope.$index + 1 }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="stu_id" label="学号" width="auto" align="center"> </el-table-column>
                        <el-table-column label="学生得分" width="auto" align="center" prop="obtain_score">
                        </el-table-column>

                        <el-table-column label="上传日期" width="auto" align="center" prop="time"> </el-table-column>
                        <el-table-column label="家长确认" width="auto" align="center" prop="time">
                            <template slot-scope="scope">
                                <div v-if="scope.row.read==1">已确认</div>
                                <el-link type="primary" v-else @click="onSend(scope.row)">未确认 <i class="el-icon-position"></i>
                                </el-link>
                            </template>
                        </el-table-column>

                    </el-table>
                </div>
            </div>
            <el-dialog :visible.sync="dialogVisible" title='导出数据' width="500px">
                <div>
                    <el-form label-width="80px" class="demo-ruleForm">
                        <el-form-item label="时间范围">
                            <el-date-picker v-model="daterange" value-format="yyyy-MM-dd" type="daterange"
                                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="doExport">确 定</el-button>
                    </span>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import Vue from 'vue'
import {
    stuEyeSearch,
    excelDaochueyeDays
} from '@/api/SchoolApi.js'
import {
    sendScore,
    historyScore,
    stuData
} from '@/api/TeacherApi.js'
import { Message } from 'element-ui';
export default {
    computed: {
        ...mapGetters(['Status'])
    },
    data() {
        return {
            stuUserID: '',
            StuName: '',
            dstatus: false,
            loading: false,
            cityid: '',
            stu_list: [],
            dialogVisible: false,
            daterange: [],
            examName: '',
            examDate: '',
            examSubject: '',
            examScore: '',
            detailData: null
        }
    },
    mounted() {
        this.StuInfo()
    },
    methods: {
        onSend(row) {
            
            const list = [{
					StuUserID: row.student_id,
					stu_id: row.stu_id,
					obtain_score: row.obtain_score
				}]
			const score = JSON.stringify(list)

            const data = {
                exam_date: this.detailData.exam_date,
                subject: this.detailData.subject,
                exam_name: this.detailData.exam_name,
                total_score: this.detailData.total_score,
                data: score
            }
            sendScore(data).then(res=>{
                Message.success('成绩发送')
                this.StuInfo()
            })
        },
        StuInfo() {
            const data = {id:this.$route.params.id}
            historyScore(data).then(res => {
               this.detailData = res.data.list[0]
            }).catch(err => {
                console.log(err)
            })
        },
        upStuHealth() { // 更新健康信息
            this.$router.push({
                name: 'health_update',
                params: {
                    student_id: this.stuUserID                                                                                                                                                                                                                                                                                     ``                                                      `                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       `````````````````````````````````````````````````````````````````````````   
                }
            })
        },
        doExport() {
            this.loading = true
            if (this.daterange.length == 0) {
                this.$message.error('请选择时间范围')
                return false
            }
            const data = {
                studentid: this.stuUserID, // 学生ID
                city: this.cityid,
                startdate: this.daterange[0],
                enddate: this.daterange[1]
            }
            excelDaochueyeDays(data).then(res => {
                this.loading = false
                if (res.status.code == 1) {
                    window.open(Vue.prototype.$url + res.data)
                    this.dialogVisible = false
                } else {
                    this.$message.error(res.status.msg)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

}
</script>

<style lang="scss" scoped>
//新样式
.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
}

.el-icon-arrow-down {
    font-size: 12px;
}

.stutitle {
    font-size: 30px !important;
    margin-right: 55% !important;
}

.tt {
    height: 40px;
    line-height: 40px;
    font-size: 22px;
    font-weight: 500;
}

.tabtit {
    width: 100px;
    display: inline-block;
    text-align: right;
}

tr {
    line-height: 45px;
}

#body {
    background: #f0f2f5;
}

.fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
        display: flex;
        height: 52px;
        width: 98%;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid #e9e9e9;

        p {
            font-size: 16px;
            font-family: 萍方-简;
            font-weight: normal;
            color: #000000;
            margin-left: 32px;
        }

        .btn {
            display: flex;
            align-items: center;
            width: 109px;
            height: 32px;
            opacity: 1;
            border-radius: 2px;
        }
    }

    .del {
        float: left,

    }

    .query {
        width: 98%;
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        padding-left: 32px;

        .queryLeft {
            width: 75%;
            display: flex;
            flex-wrap: wrap;

            div {
                width: 300px;
                line-height: 50px;
                text-align: left;

                .input,
                .select {
                    width: 60%;
                }
            }
        }
    }
}
</style>